import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { BreadCrumb } from "primereact/breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { surveyByIdAction } from "../../redux1/Slices/surveySlice";
import ShowCategories from "../Common/ShowCategories";
import { CustomRadioButtons } from "../../components/input/input";
import { getQuestionsAction } from "../../redux1/Slices/categorySlice";
import { formatDate } from "../../utils/commonFunctions";
import { HighlightCard, MainCard } from "../../components/cards/card";
import { employeeAction } from "../../redux1/Slices/employeesSlice";

export default function SurveyDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const survey = useSelector((state) => state.survey.surveyDetail);
    const questions = useSelector((state) => state.category.questions);
    const [dialog, setDialog] = useState({ visible: false });
    const [sendSurvey, setSendSurvey] = useState(false);

    const breadCrumbItems = [{ label: "View Survey" }];

    const repetitionOptions = [
        {
            name: "One time",
            value: "ONE_TIME",
        },
        {
            name: "Every month",
            value: "EVERY_MONTH",
        },
        {
            name: "Every second month",
            value: "EVERY_SECOND_MONTH",
        },
        {
            name: "Every quarter",
            value: "EVERY_QUARTER",
        },
        {
            name: "Every half year",
            value: "EVERY_HALF_YEAR",
        },
        {
            name: "Every year",
            value: "EVERY_YEAR",
        },
    ];

    useEffect(() => {
        if (id) {
            dispatch(surveyByIdAction({ id }));
        }
    }, [id]);

    const getCategoryQuestions = (selectedCategories) => {
        dispatch(getQuestionsAction({ selectedCategories }));
    };

    useEffect(() => {
        if (survey) {
            let categories = survey?.categories?.map((category) => category._id) || [];
            if (categories.length) {
                getCategoryQuestions(categories);
            }
        }
    }, [survey]);

    const getEmployees = () => {
        dispatch(employeeAction({}));
    };

    useEffect(() => {
        if (location.search === "?survey-share=true") {
            setSendSurvey(true);
        }
    }, [location]);

    return (
        <DashboardLayout heading={sendSurvey ? "Send Survey" : "View Survey"}>
            <>
                <BreadCrumb
                    model={breadCrumbItems}
                    home={{
                        label: "Surveys",
                        template: () => (
                            <span className="cursor-pointer" onClick={() => navigate(-1)}>
                                Surveys
                            </span>
                        ),
                    }}
                    pt={{
                        root: { className: "bg-transparent border-none p-0 font-semibold" },
                    }}
                />
                <div className="my-5">
                    <ShowCategories categories={survey?.categories} hideCheckbox={true} hideDescription={true} />
                </div>
                <MainCard
                    title={survey?.title}
                    subtitle={survey?.description}
                    dialog={dialog}
                    setDialog={setDialog}
                    background={"bg-transparent"}
                    border="none"
                    padding="p-0"
                    saveLabel="Activate"
                    saveFunction={() => {
                        navigate("/surveys/send-survey/" + id);
                    }}
                    cancelLable="Back"
                    cancelFuncation={() => navigate(-1)}
                >
                    <div className="flex flex-wrap justify-content-between">
                        <HighlightCard
                            title={survey?.status === "ACTIVE" ? "Started on" : survey?.status === "SAVED" ? "Starting on" : "Expired On"}
                            description={formatDate(survey?.status !== "INACTIVE" ? survey?.startDate : survey?.expiringOn)}
                            col={2}
                        />
                        <HighlightCard
                            title={survey?.status === "INACTIVE" ? "Next Survey Starting on" : "Expiring on"}
                            description={formatDate(survey?.status === "INACTIVE" ? survey?.nextSurveyDate : survey?.expiringOn)}
                            col={2}
                        />
                        <HighlightCard title="Repetition" description={repetitionOptions?.find((item) => item.value === survey?.repetition)?.name} col={2} />
                        <HighlightCard
                            title="Applicable for"
                            description={
                                <>
                                    {survey?.applicableFor?.length > 1 ? "Multiple Departments - " : "Single Department - "}
                                    {survey?.applicableFor?.map((dept, index) => (index < survey?.applicableFor?.length - 1 ? dept.name + ", " : dept.name))}
                                </>
                            }
                            col={6}
                        />
                    </div>
                    {Object.keys(questions)?.map((category, index) => {
                        return (
                            <div key={index} className=" my-3  p-3 border bg-white">
                                <h2>{category}</h2>
                                {questions[category]?.map((item, key) => {
                                    return (
                                        <div key={key}>
                                            <h3>{key + 1 + ". " + item?.question}</h3>
                                            <CustomRadioButtons options={item.choices} disabled className="cursor-auto" />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </MainCard>
            </>
        </DashboardLayout>
    );
}
