import React, { useState, useEffect } from "react";
import { Privatelayout } from "../layout/Privatelayout";
import SingupImg from "../../assets/images/singupleft.png";
import { CustomCheckbox, CustomDropDown, CustomForm, CustomInput, CustomInputPhoneNumber, CustomOtp, CustomPassword } from "../../components/input/input";
import PrimaryButton from "../../components/buttons/button";
import { Link, useNavigate } from "react-router-dom";
import { SocialLogin } from "../SocialButtons/SocialButton";
import { noOfEmployee } from "../../utils/constants";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { verifyOTP, registrationContext } from "../../redux1/Slices/registerSlice";
import { ResendOTP } from "otp-input-react";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { SocialLoginAction } from "../../redux1/Slices/loginSlice";
import { isAuthenticated } from "../../services/auth";
import PublicTopbar from "../../layout/PublicTopbar";
import { loginWithMicrosoftSSO } from "../../components/auth/MicrosoftAuth";

export const RegisterPage = () => {
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        otp: "",
        companyName: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        isChecked: false,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [otpverify, setOtpVerify] = useState(false);
    const [otp, setOtp] = useState("");
    const handleChange = ({ name, value }) => {
        console.log("name", name, value);
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleRegister = () => {
        let ignoreFields = ["otp"];
        if (showFormErrors(data, setData, ignoreFields)) {
            dispatch(verifyOTP({ data, setOtpVerify }));
        }
    };

    const Googlelogin = useGoogleLogin({
        onSuccess: (codeResponse) => dispatch(SocialLoginAction({ accessToken: codeResponse.access_token })),
        onError: (error) => console.log("Login Failed:", error),
    });
    useEffect(() => {
        document.documentElement.style.fontSize = 14 + "px";
        if (isAuthenticated()) {
            navigate("/dashboard");
        }
    }, [navigate]);
    console.log("data", data);

    const RegisterComponent = () => {
        return (
            <>
                {/* <PublicTopbar /> */}
                <Privatelayout
                    src={SingupImg}
                    childClassName={"flex flex-column justify-content-center py-4 align-items-center col-8 "}
                // heading="Register to get started."
                // subheading="Join us by creating your account."
                >
                    <CustomForm>
                        <CustomInput
                            label="First Name "
                            labelClassName="text-white"
                            className="bg-white w-full border-round-lg"
                            required
                            col={12}
                            placeholder="Enter "
                            name="firstName"
                            data={data}
                            onChange={handleChange}
                        />

                        <CustomInput
                            label="Last Name "
                            labelClassName="text-white"
                            className="bg-white w-full border-round-lg"
                            required
                            col={12}
                            placeholder="Enter "
                            name="lastName"
                            data={data}
                            onChange={handleChange}
                        />
                        <CustomInput
                            label="Company Name "
                            labelClassName="text-white"
                            className="bg-white w-full border-round-lg"
                            required
                            col={12}
                            placeholder="Enter "
                            name="companyName"
                            data={data}
                            onChange={handleChange}
                        />
                        <CustomInputPhoneNumber
                            labelClassName="text-white"
                            label="Phone Number"
                            className="bg-white w-full border-round-lg "
                            required
                            col={12}
                            placeholder="Enter "
                            name="phoneNumber"
                            data={data}
                            onChange={handleChange}
                        ></CustomInputPhoneNumber>
                        <CustomInput
                            label="Email Address "
                            labelClassName="text-white"
                            className="bg-white w-full border-round-lg"
                            required
                            col={12}
                            placeholder="Enter Email"
                            name="email"
                            data={data}
                            onChange={handleChange}
                        />
                        <CustomPassword
                            labelClassName="text-white"
                            label="Password"
                            inputClassName="bg-white w-full border-round-lg "
                            col={12}
                            placeholder="************"
                            name="password"
                            data={data}
                            onChange={handleChange}
                            feedback={true}
                            meter={true}
                        />
                        <CustomPassword
                            labelClassName="text-white"
                            label="Confirm Password"
                            inputClassName="bg-white w-full border-round-lg"
                            col={12}
                            placeholder="************"
                            name="confirmPassword"
                            data={data}
                            onChange={handleChange}
                            feedback={false}
                            hideFooter={true}
                        />
                        <CustomCheckbox optionLabel="I accept  Terms of use and Privacy policy." name="isChecked" data={data} onChange={handleChange} />
                        <PrimaryButton label="Register" className={"bg-white text-primary"} extraClass="w-full" col={12} disable={data?.isChecked ? false : true} onClick={handleRegister} />
                        <SocialLogin microsoftlabel="Sign In with Microsoft" onMicrosoftClick={() => loginWithMicrosoftSSO(dispatch, navigate)} />
                        <p className=" text-center text-white font-normal col-12 ">
                            Already have Account? &nbsp;
                            <Link to="/login" className="no-underline text-skyblue font-semibold">
                                Login here
                            </Link>
                        </p>
                    </CustomForm>
                </Privatelayout>
            </>
        );
    };
    const renderButton = (buttonProps) => {
        return (
            <button className="border-none bg-transparent text-skyblue text-lg font-medium mb-2 shadow-none" {...buttonProps}>
                {buttonProps.remainingTime !== 0 ? `Resend Code in  ${buttonProps.remainingTime} sec` : "Resend"}
            </button>
        );
    };
    const renderTime = (remainingTime) => {
        return (
            <span className="col-12  mx-0 px-0 ">
                {/* <p className="mt-0 flex align-items-end text-blue-300 ">{remainingTime} sec</p> */}
                <PrimaryButton label="Continue" className={"bg-white text-primary "} extraClass="w-full m-2" onClick={() => dispatch(registrationContext({ data, otp, navigate }))} />
            </span>
        );
    };

    const VerifyOtpComponent = () => {
        return (
            <>
                <Privatelayout
                    childClassName=" flex flex-column justify-content-center align-items-center "
                    src={SingupImg}
                    heading="We Send a code to your email"
                    subheading="Enter the 6-digit verification code sent to "
                    subtext={data?.email ? data?.email?.replace(data?.email?.substring(1, 4), "***") : "z****@gmail.com"}
                >
                    <CustomForm>
                        <CustomOtp onChange={(e) => setOtp(e)} value={otp} col={12} />
                        <p className="col-12 m-0 text-lg  font-medium" style={{ color: "#425466" }}>
                            <ResendOTP onResendClick={() => dispatch(verifyOTP({ data: data, setOtpVerify }))} renderTime={renderTime} renderButton={renderButton} className="flex-column-reverse " />
                        </p>
                    </CustomForm>
                </Privatelayout>
            </>
        );
    };

    return <>{otpverify ? VerifyOtpComponent() : RegisterComponent()}</>;
};
