import React, { useEffect, useState } from "react";
import { MainCard } from "../../components/cards/card";
import CustomTable from "../../components/table/table";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as NoServices } from "../../assets/svg/no-services.svg";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/commonFunctions";
import { useDebounce } from "primereact/hooks";
import { requestedServicesAction } from "../../redux1/Slices/servicesSlice";

export default function ServiceTable({ status }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const requestedServices = useSelector((state) => state.services.requested);
    const [searchFor, searchString, setSearchFor] = useDebounce("", 1000);
    const [dates, setDates] = useState(null);

    // Table
    const columns = [
        { field: "createdAt", header: "Date", body: "DATE", sortable: true },
        { field: "category.name", header: "Category", sortable: true },
        { field: "service.name", header: "Service", sortable: true },
        { field: "status", header: "Status", body: "STATUS", sortable: true },
        { field: "", header: "Actions", body: "ACTION", style: { width: "15%" } },
    ];

    const emptyMessage = () => {
        return (
            <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
                <NoServices />
                <h2>No Service Requests Listed</h2>
            </div>
        );
    };

    const searchJob = (searchString) => {
        const searchKeys = ["status", "category.name", "service.name"];
        const search = `searchKeys=${JSON.stringify(searchKeys)}&searchString=${searchString}`;
        return search;
    };

    const filterDates = (dates) => {
        let filterDates = {
            startDate: formatDate(dates[0], "YY-MM-DD"),
            endDate: dates[1] !== null ? formatDate(dates[1], "YY-MM-DD") : formatDate(dates[0], "YY-MM-DD"),
        };
        return filterDates;
    };

    // UseEffects

    useEffect(() => {
        let query = ``;
        if (dates?.length) {
            query = `filterData=${JSON.stringify({ createdAt: filterDates(dates) })}`;
        }
        if (searchString) {
            query = "&" + searchJob(searchString);
        }
        dispatch(requestedServicesAction({ query }));
    }, [dispatch, searchString, dates]);

    return (
        <MainCard search onChange={setSearchFor} dateFilter={setDates} filterKey="Date" dates={dates}>
            <CustomTable columns={columns} emptyMessage={emptyMessage} data={requestedServices} view={(col) => navigate("request-list/view/" + col._id)} />
        </MainCard>
    );
}
