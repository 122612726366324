import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout";
import { BreadCrumb } from "primereact/breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { AnalyticsQuestionCard, MainCard } from "../../components/cards/card";
import { useDispatch, useSelector } from "react-redux";
import { surveyAnalyticsAction } from "../../redux1/Slices/analyticsSlice";
import moment from "moment";
import { Chip } from "primereact/chip";
import { firstLetterToUppercase } from "../../utils/regex";
import DonutChat from "../../components/Charts/DonutChat";
import CustomSkelton from "../../components/cards/CustomSkelton";
import { CustomDropDown } from "../../components/input/input";
import { months } from "../../utils/constants";
import PrimaryButton, { ButtonPosition } from "../../components/buttons/button";
import { ReactComponent as NoRecords } from "../../assets/svg/no-results.svg";

export default function SurveyAnalytics() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const analyticsData = useSelector((state) => state.analytics.surveyAnalytic);
    const isLoading = useSelector((state) => state.loader.isLoading);
    const [isMonthFilter, setIsMonthFilter] = useState(false);
    const [filterMonth, setFilterMonth] = useState("");
    const [filterCategory, setFilterCategory] = useState("");
    const [filterDepartment, setFilterDepartment] = useState("");

    const breadCrumbItems = [{ label: "View Survey" }];
    const MonthFilterModel = ({ display = "hidden" }) => {
        return (
            <div className={`w-15rem border bg-white shadow-1 z-5 right-0 top-100 absolute ${display}`}>
                <div className="p-3 pb-1">
                    <h3 className="mt-0">Filter</h3>
                    {analyticsData?.repetition !== "ONE_TIME" ? (

                        <CustomDropDown
                            extraClassName="mb-2"
                            placeholder="Select Month"
                            label="Select Month"
                            value={filterMonth}
                            name="year"
                            options={months.map((month) => ({ name: month.fullName, value: month.fullName }))}
                            onChange={(e) => setFilterMonth(e.value)}
                        />
                    ) : ""}
                    <CustomDropDown
                        extraClassName="mb-2"
                        placeholder="Select Category"
                        label="Select Category"
                        value={filterCategory}
                        name="year"
                        options={analyticsData?.allCategories?.map((cat) => ({ name: cat.name, value: cat.id }))}
                        onChange={(e) => setFilterCategory(e.value)}
                    />
                    <CustomDropDown
                        extraClassName="mb-2"
                        placeholder="Select Department"
                        label="Select Department"
                        value={filterDepartment}
                        name="year"
                        options={analyticsData?.allDepartments?.map((dept) => ({ name: dept.name, value: dept.id }))}
                        onChange={(e) => setFilterDepartment(e.value)}
                    />
                </div>
                <hr className="border-50" />
                <div className="p-3 pt-1">
                    <ButtonPosition>
                        <PrimaryButton label="Apply" disable={filterMonth === "" && filterCategory === "" && filterDepartment === ""} onClick={handleFilter} />
                        <PrimaryButton
                            label="Clear"
                            secondary
                            onClick={() => {
                                setFilterMonth("");
                                setFilterCategory("");
                                setFilterDepartment("");
                                setIsMonthFilter(false);
                            }}
                        />
                    </ButtonPosition>
                </div>
            </div>
        );
    };

    const handleFilter = () => {
        dispatch(surveyAnalyticsAction({ id, month: filterMonth, category: filterCategory, department: filterDepartment }));
        setIsMonthFilter(false);
    };

    useEffect(() => {
        if (id && filterMonth === "" && filterCategory === "" && filterDepartment === "") dispatch(surveyAnalyticsAction({ id }));
    }, [id, dispatch, filterCategory, filterMonth, filterDepartment]);

    return (
        <DashboardLayout heading="Analytics">
            <>
                <div className="flex justify-content-between align-items-center">
                    <BreadCrumb
                        model={breadCrumbItems}
                        home={{
                            label: "Surveys",
                            template: () => (
                                <span className="cursor-pointer" onClick={() => navigate(-1)}>
                                    Surveys
                                </span>
                            ),
                        }}
                        pt={{
                            root: { className: "bg-transparent border-none p-0 font-semibold" },
                        }}
                    />
                    <div className="flex relative align-items-center gap-2">
                        {filterMonth !== "" ? (
                            <Chip
                                template={() => (
                                    <div className="flex align-items-center gap-3 py-1">
                                        <span>{filterMonth}</span> <i className="pi pi-times cursor-pointer" onClick={() => setFilterMonth("")} />
                                    </div>
                                )}
                                className="bg-primary text-white"
                                style={{ borderRadius: "8px" }}
                            />
                        ) : (
                            ""
                        )}
                        <p className="text-skyblue cursor-pointer" onClick={() => setIsMonthFilter((prev) => !prev)}>
                            Filter
                        </p>
                        <i className="pi pi-filter text-skyblue cursor-pointer" onClick={() => setIsMonthFilter((prev) => !prev)} />
                        <MonthFilterModel display={isMonthFilter ? "block" : "hidden"} />
                    </div>
                </div>
                {analyticsData?.title || isLoading ? (
                    <>
                        <MainCard background={"bg-transparent"} border="none" padding="p-0" height="auto">
                            <div className="flex">
                                <h2 className="my-0">{isLoading ? <CustomSkelton width="10rem" height="2rem" /> : analyticsData?.title}</h2>
                                <h2 className="my-0 mx-3 font-normal text-skyblue">
                                    {isLoading ? <CustomSkelton width="10rem" height="2rem" /> : `(${moment(analyticsData?.startDate).format("DD-MM-YYYY")})`}
                                </h2>
                                {isLoading ? (
                                    <CustomSkelton width="10rem" height="2rem" />
                                ) : (
                                    <Chip
                                        label={"Repetition: " + (analyticsData?.repetition && firstLetterToUppercase(analyticsData?.repetition?.split("_")?.join(" ")?.toLowerCase()))}
                                        className="bg-skyblue text-white "
                                    />
                                )}
                            </div>
                            <div className="flex gap-3 my-3">
                                {isLoading ? (
                                    <CustomSkelton width="10rem" height="2rem" />
                                ) : (
                                    analyticsData?.departments?.map((dept, index) => <Chip label={dept.name} key={index} className="bg-primary px-4" />)
                                )}
                            </div>
                            {analyticsData?.responseRate ?
                                <div className="flex my-3 align-items-center gap-3">
                                    <p className="text-skyblue">Response Rate</p>
                                    <h2>{isLoading ? <CustomSkelton width="3rem" height="2rem" /> : analyticsData?.responseRate + "%"}</h2>
                                </div> : ""
                            }
                            { }
                            <div className="flex gap-3 flex-wrap">
                                {analyticsData?.categories?.map((category, index) => (
                                    <DonutChat data={category} key={index} isLoading={isLoading} />
                                ))}
                            </div>
                        </MainCard>
                        {isLoading ? (
                            <>
                                <CustomSkelton width="20%" height="2rem" />
                                <CustomSkelton width="75%" height="2rem" />
                                <CustomSkelton width="50%" height="2rem" />
                            </>
                        ) : (
                            analyticsData?.categories?.map((category, index) => (
                                <MainCard>

                                    <div key={index}>
                                        <h2>{category?.name}</h2>
                                        <AnalyticsQuestionCard data={category?.questions} numberOfEmployees={analyticsData?.numberOfEmployees} />
                                    </div>
                                </MainCard>
                            ))

                        )}
                    </>
                ) : (
                    <MainCard>
                        <div className="flex justify-content-center py-8">
                            <NoRecords />
                        </div>
                    </MainCard>
                )}
            </>
        </DashboardLayout>
    );
}
