import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import LineChart from "../../components/Charts/LineChart";
import PrimaryButton, { PrimaryButtonOutlined } from "../../components/buttons/button";
import CustomTable from "../../components/table/table";
import { useDispatch, useSelector } from "react-redux";
import { departmentAction } from "../../redux1/Slices/departmentSlice";
import { categoryScoresAction, categoryScoresByIdAction, departmentScoresAction, focusAreaAction } from "../../redux1/Slices/analyticsSlice";
import { months } from "../../utils/constants";
import { setDepartmentsData } from "./common";
import { Menu } from "primereact/menu";
import { CustomDropDown } from "../../components/input/input";
import { ImproveCard } from "../../components/cards/card";
import Constants from "../../services/constants";
import useNewestSurveyResults from "../../queries/useNewestSurveyResults";

export default function CategoryOverview() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const deptFilter = useRef(null);
    const categoryScores = useSelector((state) => state.analytics.categoryScoresByDept);
    const departments = useSelector((state) => state.department.data);
    const departmentScores = useSelector((state) => state.analytics.departmentScores);
    const navigate = useNavigate();
    const [deptId, setDeptId] = useState(null);
    const [catScores, setCatScores] = useState([]);
    const [deptColumns, setDeptColumns] = useState([]);
    const [deptScores, setDeptScores] = useState([]);
    const [categoryName, setCategoryName] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("ourSuggestions");

    const surveyResults = useNewestSurveyResults(null, id, 69);

    const howToImproveList = surveyResults?.data ? 
        Object.keys(surveyResults?.data).map(item => 
        ({ departmentName: item, howToImprove: surveyResults?.data[item][0]?.howToImprove, score: surveyResults?.data[item][0]?.averageScore || "" })) : []

        


    const breadCrumbItems = [{ label: categoryName ? categoryName : "Category Details" }];

    const attentionTableColumns = [
        { field: "departmentName", header: "Department", body: 'FOCUS_AREA' },
        { field: "suggestion", header: "Reason for focus", body: "PARAGRAPH", style: { whiteSpace: "normal", wordBreak: "break-word" } },

        
    ];

    const howToImproveOptions = [
        { field: "ourSuggestions", name: "Our Suggestions" },
        { field: "topThreeServices", name: "Top Three Services" },
        { field: "otherCompanySuggestions", name: "Suggestions from other Companies" },
    ];

    // const categoryScoreColumns = [
    //     { field: "department", header: "Department" },
    //     { field: "Dec", header: "Dec" },
    //     { field: "Jan", header: "Jan" },
    //     { field: "Feb", header: "Feb" },
    // ];

    const formatCategoryScores = (categoryScores) => {
        setCategoryName(Object.values(categoryScores).length ? Object.values(categoryScores)[0].categoryName : "");
        let scores = [];
        months?.forEach((month, key) => {
            // if (categoryScores?.length) {
            let data = {
                month: month.fullName,
                value: categoryScores[month.fullName]?.overAllScore || null,
                color: categoryScores[month.fullName]?.color || null,
            };
            // categoryScores?.forEach((item) => {
            //     if (item.month === month.fullName) {
            //         data.value = item?.overAllScore;
            //     }
            // });
            scores.push(data);
            // } else scores.push({ month: month.fullName });
        });

        setCatScores(scores);
    };

    useEffect(() => {
        if (id) {
            dispatch(departmentAction({}));
            dispatch(departmentScoresAction({ categoryId: id }));
        }
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(categoryScoresByIdAction({ id, deptId }));
    }, [id, deptId, dispatch]);

    useEffect(() => {
        setDepartmentsData(departments, departmentScores, setDeptColumns, setDeptScores);
    }, [departmentScores, departments]);

    useEffect(() => {
        if (categoryScores) {
            formatCategoryScores(categoryScores);
        }
    }, [categoryScores]);

    return (
        <DashboardLayout heading={categoryName ? categoryName : "Category Details"}>
            <BreadCrumb
                model={breadCrumbItems}
                home={{
                    label: "Dashboard",
                    template: () => (
                        <span className="cursor-pointer" onClick={() => navigate("/")}>
                            Dashboard
                        </span>
                    ),
                }}
                pt={{
                    root: { className: "bg-transparent border-none p-0 font-semibold capitalize" },
                }}
            />
            <div className="flex flex-wrap my-3 align-items-start">
                <div className="col-12 p-0 lg:col-7 pr-3">
                    <div className="border bg-white mb-3 p-3">
                        <div className="flex justify-content-between">
                            <div className="col-6 pl-2">
                                <h2 className="m-0 text-xl">Score over Time</h2>
                                {deptId ? (
                                    departments?.map((dept) =>
                                        dept._id === deptId ? (
                                            <span className="font-light text-sm">
                                                (<b>Department: </b>
                                                {dept.name})
                                            </span>
                                        ) : null
                                    )
                                ) : (
                                    <span className="font-light text-sm">
                                        <b>Departments: </b>
                                        All
                                    </span>
                                )}
                            </div>
                            <div className="flex gap-3 col-6 justify-content-end">
                                {deptId ? <PrimaryButton label="Clear Filter" className="bg-skyblue border-none" onClick={() => setDeptId(null)} /> : null}
                                <PrimaryButtonOutlined
                                    label="Departments"
                                    icon="pi pi-filter"
                                    iconPos="right"
                                    className={!deptId ? "border-none" : ""}
                                    onClick={(event) => deptFilter.current.toggle(event)}
                                />
                            </div>
                            <Menu
                                model={departments?.map((dept) => ({ label: dept.name, command: () => setDeptId(dept._id), className: deptId === dept._id ? "bg-skyblue" : "" }))}
                                popup
                                ref={deptFilter}
                                id="popup_menu_right"
                                popupAlignment="right"
                            />
                        </div>
                        <LineChart data={[{ name: "Score", color: catScores?.filter((score) => score.color)?.map((score) => score.color)[0], data: catScores?.filter((score) => score.value !== undefined)?.map((score) => score.value) || [] }]} colors={catScores?.filter((score) => score.color)?.map((score) => score.color) || []} />
                    </div>
                </div>
                <div className="col-12 border p-3 bg-white lg:col-5">
                    <h2 className="mt-0 mb-3 text-xl pl-2 capitalize">{categoryScores ? categoryScores?.categoryName : "Category"} Score</h2>
                    <div className="border ">
                        <CustomTable showLines={false} columns={deptColumns} data={deptScores} />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap my-3 align-items-start">
                <div className="col-12 p-0 ">
                    <div className="bg-white p-3 border-1 border-300 border-round-xl">
                        <h2 className="mt-0 mb-3 text-xl pl-2">Focus areas</h2>
                        <div className=" ">
                            <CustomTable extraClassName="focus-table" columns={attentionTableColumns} data={surveyResults?.data ? Object.keys(surveyResults?.data).map(item => ({ departmentName: item, suggestion: surveyResults?.data[item][0]?.summary, score: surveyResults?.data[item][0]?.averageScore || "" })) : []} showLines={false} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-12 lg:12 bg-white border">
                <div className={`p-5 mb-5 `}>
                    <div className="flex align-items-center justify-content-between">
                        {/* <h2 className="mt-0 mb-3 text-xl pl-2">How to improve</h2>
                        <CustomDropDown col={3} options={howToImproveOptions} optionLabel="name" optionValue="field" label="" value={selectedFilter} onChange={(e) => setSelectedFilter(e.value)} /> */}
                    </div>
                    <div className="border p-5">
                        {howToImproveList?.map(item => {
                            return (item?.howToImprove ?
                                <ImproveCard
                                        id={item?._id}
                                        category={item?.category?._id}
                                        src={item.suggestionIcon ? Constants.BACKEND_URL + item.suggestionIcon : item.icon ? Constants.BACKEND_URL + item.icon : ""}
                                        title={item?.title ? item?.title : item?.name ? item?.name : ""}
                                        data={item?.howToImprove}
                                        selectedFilter={selectedFilter}
                                        navigate={navigate}
                                    /> : null
                                ) 
                            })}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}
