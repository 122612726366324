import { useQuery } from "@tanstack/react-query";
import { api } from "../services/api";

const fetchNewestSurveyResults = async (departmentIds, categoryId, maxAverageScore) => {
    const queryParams = new URLSearchParams({
        ...(departmentIds ? { departmentIds: departmentIds.join(",") } : {}),
        ...(categoryId ? { categoryId } : {}),
        ...(maxAverageScore ? { maxAverageScore } : {}), // Added maxAverageScore parameter
    });
    const response = await api("get", `survey-results/newest?${queryParams}`); // Updated endpoint
    if (!response.success) {
        throw new Error(response.message);
    }
    return response.data;
};

const useNewestSurveyResults = (departmentIds, categoryId, maxAverageScore) => {
    // New hook
    return useQuery({
        queryKey: ["newestSurveyResults", departmentIds, categoryId, maxAverageScore], // Updated query key
        queryFn: () => fetchNewestSurveyResults(departmentIds, categoryId, maxAverageScore), // Updated fetch function
    });
};

export default useNewestSurveyResults; // Exporting new hook
