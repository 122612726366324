import React, { useEffect, useRef, useState } from "react";
import { api, multipartApi } from "../../services/api";
import Constants from "../../services/constants";
import { authenticate } from "../../services/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from 'query-string';


export const ImpersonateUser = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        (async () => {
            const { userid } = queryString.parse(location.search); // Parse the query string
    
            if (userid) {
                await handleImpersonate(userid).then(() => {});
            }
        })();
        
        
    });

    const handleImpersonate = async (userId) => {
        try {
            const res = await api("get", `admin/impersonate/${userId}`)
            if (res.success && res.data) {
                if (typeof window !== "undefined") {
                    localStorage.setItem("authToken", JSON.stringify(res.data.token));
                }
                navigate("/dashboard")
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    return (
        <div>        
        </div>
    );
};
