import { configureStore } from "@reduxjs/toolkit";
import loaderReducer from "./Slices/loaderSlice";
import toastReducer from "./Slices/toastSlice";
import userReducer from "./Slices/loginSlice";
import departmentReducer from "./Slices/departmentSlice";
import categoryReducer from "./Slices/categorySlice";
import surveyReducer from "./Slices/surveySlice";
import serviceReducer from "./Slices/servicesSlice";
import employeeReducer from "./Slices/employeesSlice";
import analyticsReducer from "./Slices/analyticsSlice";
import helpReducer from "./Slices/helpSlice";
import notificationReducer from "./Slices/notificationSlice";
import thunk from "redux-thunk";

const store = configureStore({
    reducer: {
        loader: loaderReducer,
        toast: toastReducer,
        user: userReducer,
        department: departmentReducer,
        category: categoryReducer,
        survey: surveyReducer,
        services: serviceReducer,
        employees: employeeReducer,
        analytics: analyticsReducer,
        help: helpReducer,
        notification: notificationReducer,
    },
    middleware: [thunk],
});

export default store;
