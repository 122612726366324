import Onboarding from "../views/Onboarding";
import { Dashboard } from "../views/Dashboard";
import SuccessScreen from "../views/Onboarding/successScreen";
import Settings from "../views/Settings/Settings";
import DepartmentForm from "../views/Settings/Departments/DepartmentForm";
import Surveys from "../views/Surveys/Surveys";
import { CreateSurvey } from "../views/Surveys/CreateSurvey";
import SurveyDetails from "../views/Surveys/SurveyDetails";
import Services from "../views/Services/Services";
import RequestService from "../views/Services/RequestService";
import CategoryOverview from "../views/Dashboard/CategoryOverview";
import DepartmentOverview from "../views/Dashboard/DepartmentOverview";
import Employees from "../views/Employees/Employees";
import ViewService from "../views/Services/ViewService";
import AllFocusArea from "../views/Dashboard/AllFocusArea";
import Membership from "../views/MemberShip Plan/Membership";
import CustomizePlan from "../views/MemberShip Plan/CustomizePlan";
import ManageEmployee from "../views/Employees/ManageEmployee";
import Help from "../views/Help/Help";
import Notifications from "../views/Notifications/Notifications";
import SurveySharedTo from "../views/Surveys/SurveySharedTo";
import EditService from "../views/Services/EditService";
import SurveyAnalytics from "../views/Surveys/SurveyAnalytics";
import { ImpersonateUser } from "../views/Impersonate";
export const PrivateRoutes = [
    {
        path: "/setup-board",
        name: "On Boarding",
        element: <Onboarding />,
    },
    {
        path: "/survey-created/:id",
        name: "Congratulations",
        element: <SuccessScreen />,
    },

    // Dashboard
    {
        path: "/dashboard",
        name: "Dashboard",
        element: <Dashboard />,
    },
    {
        path: "/dashboard/category/:id",
        name: "Category Overview",
        element: <CategoryOverview />,
    },
    {
        path: "/dashboard/department/:id",
        name: "Department Overview",
        element: <DepartmentOverview />,
    },
    {
        path: "/dashboard/focus-area",
        name: "Focus Area",
        element: <AllFocusArea />,
    },

    // Surveys
    {
        path: "/surveys",
        name: "Surveys",
        element: <Surveys />,
        redirect: "/surveys/active",
        exact: true,
        items: [
            {
                name: "Active",
                path: "active",
                element: "",
                exact: true,
            },
            {
                name: "Saved",
                path: "saved",
                element: "",
                exact: true,
            },
            {
                name: "Inactive",
                path: "inactive",
                element: "",
                exact: true,
            },
        ],
    },
    {
        name: "Create Survey",
        path: "/surveys/create-survey",
        element: <CreateSurvey />,
        exact: true,
    },
    {
        name: "Create Survey",
        path: "/surveys/analytics/:id",
        element: <SurveyAnalytics />,
        exact: true,
    },
    {
        name: "Create Survey",
        path: "/surveys/edit/:id",
        element: <CreateSurvey />,
        exact: true,
    },
    {
        name: "View Survey",
        path: "/surveys/send-survey/:id",
        element: <SurveySharedTo />,
        exact: true,
    },
    {
        name: "View Survey",
        path: "/surveys/view-survey/:id",
        element: <SurveyDetails />,
        exact: true,
    },

    // Services
    {
        name: "Service Request",
        path: "/services",
        element: <Services />,
        items: [
            {
                name: "Services",
                path: "/services",
                element: <Services />,
                exact: true,
            },
            {
                name: "Service requests",
                path: "/services/request-list",
                element: <RequestService />,
                exact: true,
            },
        ],
        exact: true,
    },
    {
        name: "Service Request",
        path: "/services/request-list/view/:id",
        element: <ViewService />,
        exact: true,
    },
    {
        name: "Service Request",
        path: "/services/request-list/edit/:id",
        element: <EditService />,
        exact: true,
    },
    // Employees
    {
        name: "Employees",
        path: "/employees",
        element: <Employees />,
        exact: true,
    },
    {
        name: "Employees",
        path: "/employees/add",
        element: <ManageEmployee />,
        exact: true,
    },
    {
        name: "Employees",
        path: "/employees/update/:id",
        element: <ManageEmployee />,
        exact: true,
    },

    // Notifications
    {
        name: "Notifications",
        path: "/notifications",
        element: <Notifications />,
        exact: true,
    },

    // Help
    {
        name: "Help",
        path: "/help",
        element: <Help />,
        exact: true,
    },

    // Settings
    {
        path: "/settings",
        name: "Settings",
        element: <Settings />,
        exact: true,
        items: [
            {
                name: "Edit Profile",
                path: "edit-profile",
                element: "",
                exact: true,
            },
            {
                name: "Change Password",
                path: "change-password",
                element: "",
                exact: true,
            },
            {
                name: "Manage Departments",
                path: "manage-departments",
                element: "",
            },
            {
                name: "Plans",
                path: "plans",
                element: "",
                exact: true,
            },
            {
                name: "Terms of Use",
                path: "tems-of-use",
                element: "",
                exact: true,
            },
            {
                name: "About",
                path: "about",
                element: "",
                exact: true,
            },
            {
                name: "Privacy Policy",
                path: "privacy-policy",
                element: "",
                exact: true,
            },
            {
                name: "Deactivate Account",
                path: "deactivate-account",
                element: "",
                exact: true,
            },
        ],
    },
    {
        name: "Add Departments",
        path: "/settings/manage-departments/add",
        element: <DepartmentForm />,
        exact: true,
    },
    {
        name: "Edit Departments",
        path: "/settings/manage-departments/edit/:id",
        element: <DepartmentForm />,
        exact: true,
    },
    {
        name: "CustomizePlan",
        path: "/membership/customize",
        element: <CustomizePlan />,
        exact: true,
    },

    // MemberShip
    {
        path: "/membership",
        name: "Membership",
        element: <Membership />,
        exact: true,
        items: [
            {
                name: "Monthly",
                path: "monthly",
                element: "",
                exact: true,
            },
            {
                name: "Yearly",
                path: "yearly",
                element: "",
                exact: true,
            },
        ],
    },
    {
        path: "/impersonate",
        name: "Impersonate User",
        element: <ImpersonateUser />,
    },
];
