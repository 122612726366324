import React from "react";
import ReactApexChart from "react-apexcharts";

export default function LineChart({ data, colors = ["#66C2CB"] }) {
    const options = {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },

            toolbar: {
                show: false,
                tools: {
                    download: false,
                },
            },
        },
        // fill: {
        //     colors: colors,
        //     opacity: 0,
        //     type: "gradient",
        //     gradient: {
        //         opacityFrom: 0.8,
        //         opacityTo: 0.2,
        //     },
        // },
        legend: {
            markers: {
                fillColors: colors,
            },
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            y: {
                formatter: (value) => (value !== null ? value : "N/A"),
            },
            hideEmptySeries: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
            width: 5,
            colors: colors,
        },
        grid: {
            strokeDashArray: 7,
            borderColor: "#F1F1F1",
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            row: {
                colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        markers: {
            size: 5,
            colors: colors,
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "dec"],
        },
        yaxis: {
            stepSize: 10,
            min: 0,
            max: 100,
            decimalsInFloat: 0,
        },
        noData: {
            text: "Data not available",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: undefined,
                fontSize: "14px",
                fontFamily: undefined,
            },
        },
    };
    return (
        <div id="chart">
            <ReactApexChart options={options} series={data || { name: "Score", data: [] }} type="line" height={350} />
        </div>
    );
}
