import React, { useEffect, useState } from "react";
import Guidelines from "./Guidelines";
import Logo from "../../../assets/images/logo.png";
import { ReactComponent as NoRecord } from "../../../assets/svg/no-record.svg";
import { MainCard } from "../../../components/cards/card";
import { CustomDropDown, CustomInput, CustomInputNumber, CustomRadioButtons } from "../../../components/input/input";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitSurveyAction, surveyByIdAction } from "../../../redux1/Slices/surveySlice";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ProgressBar } from "primereact/progressbar";
import PrimaryButton, { ButtonPosition } from "../../../components/buttons/button";
import { showToast } from "../../../redux1/Slices/toastSlice";
import { allValidations } from "../../../utils/formValidations";
import { formatDate, showFormErrors } from "../../../utils/commonFunctions";
import EnterIcon from "../../../assets/icons/enter.png"
import { Skeleton } from "primereact/skeleton";
import { useTranslation } from 'react-i18next';
import moment from "moment";

export default function ViewSurvey() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const survey = useSelector((state) => state.survey.surveyDetail);
    const isLoading = useSelector((state) => state.loader.isLoading);
    const [isGuideLines, setIsGuideLines] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [isAnswered, setIsAnswered] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [progress, setProgress] = useState(0);
    const [data, setData] = useState({
        employee: searchParams.get("empId"),
        categories: [],
    });
    const [language, setLanguage] = useState("En");


    useEffect(() => {
        i18n.changeLanguage(language.toLowerCase())
    }, [language])

    // const handleChange = ({ name, value }) => {
    //     const formErrors = allValidations(name, value, data);
    //     setData((prev) => ({ ...prev, [name]: value, formErrors }));
    // };

    const handleAnswer = ({ name, value, e }) => {
        let alreadyAnswered = false;
        let allAns = [];
        answers?.forEach((ans) => {
            if (ans.question === questions[activeQuestion]?._id) {
                alreadyAnswered = true;
                let updatedAns = {
                    category: questions[activeQuestion]?.categoryId,
                    question: questions[activeQuestion]?._id,
                    answer: value?.name,
                    value: value?.value,
                    answerId: value?._id,
                };

                allAns.push(updatedAns);
            } else allAns.push(ans);
        });
        if (alreadyAnswered) setAnswers(allAns);
        else setAnswers([...answers, { category: questions[activeQuestion]?.categoryId, question: questions[activeQuestion]._id, answer: value?.name, value: value?.value, answerId: value?._id }]);
        setIsAnswered(true);
    };

    const showNextQuestion = () => {
        if (showFormErrors(data, setData)) {
            isAnswered ? setActiveQuestion((prev) => prev + 1) : dispatch(showToast({ severity: "error", summary: "Please choose a answer" }));
        }
    };

    // useEffect(() => {
    //     setData({ ...data, employee: searchParams.get("empId") });
    // }, [searchParams]);

    useEffect(() => {
        setProgress((answers.length / questions.length) * 100);
        // answers.forEach((answer) => {
        //     console.log("answer", answer, questions[activeQuestion]._id);
        //     if (answer.question === questions[activeQuestion]._id) setIsAnswered(true);
        // });
    }, [activeQuestion]);

    useEffect(() => {
        dispatch(surveyByIdAction({ id, empId: searchParams.get("empId") }));
    }, [id, searchParams]);

    useEffect(() => {
        if (survey?.questions) {
            setQuestions(randomQuestions(survey?.questions));
        }
        setData({ ...data, categories: survey?.categories?.map((category) => category?._id) });
    }, [survey]);

    const randomQuestions = (arr) => {
        if (arr.length === 1) {
            return arr;
        }
        const random = Math.floor(Math.random() * arr.length);
        return [arr[random], ...randomQuestions(arr.filter((_, i) => i != random))];
    };

    const saveSurvey = () => {
        if (answers.length === questions.length) {
            if (showFormErrors(data, setData)) {
                let surveyData = {
                    ...data,
                    questionAnswers: answers,
                    survey: id,
                };
                dispatch(submitSurveyAction({ surveyData, navigate }));
            }
        } else {
            dispatch(showToast({ severity: "error", summary: "Please choose a answer" }));
        }
    };

    const handleQuestionChange = (index) => {
        let isDone = false;
        answers?.forEach((ans) => {
            if (ans.question === questions[index]?._id) {
                isDone = true;
            }
        });
        setActiveQuestion(index);
        setIsAnswered(isDone);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
        }
    };

    useEffect(() => {
        handleQuestionChange(activeQuestion);
    }, [activeQuestion]);

    useEffect(() => {
        function handleKeyPress(event) {
            if (event.key === "Enter") {
                const nextBtn = document.getElementById("nextBtn");
                if (nextBtn) {
                    nextBtn.click();
                }
            }
        }

        document.body.addEventListener("keydown", handleKeyPress);

        return () => {
            document.body.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    // const genderOptions = [{ name: "Male" }, { name: "Female" }, { name: "other" }];

    return (
        <>
            <div className="p-5">
                <div className="text-center relative">
                    <img src={Logo} width={282} height={49} alt="Logo" />
                    <CustomDropDown label={""} extraClassName={'absolute top-0 w-6rem right-0'} options={["En", "Da"]} optionLabel="" value={language} onChange={(e) => setLanguage(e.value)} />
                </div>

                {isLoading ? (
                    <>
                        <Skeleton height="30rem" className="mb-2" />
                        <Skeleton className="mb-2" /> <Skeleton /> <Skeleton />{" "}
                    </>
                ) : survey?.isAlreadyFilled ? (
                    <div className="text-center flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 200px)" }}>
                        <NoRecord />
                        <h2>{t("You have already filled this Survey!")}</h2>
                    </div>
                ) : !survey?._id ? (
                    <div className="text-center flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 200px)" }}>
                        <NoRecord />
                        <h2>{t("Link expired or Survey not found!")}</h2>
                    </div>
                ) : moment().isBefore(moment(survey?.startDate)) ? (
                    <div className="text-center flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 200px)" }}>
                        <NoRecord />
                        <h2>{t("Cannot fill survey before Start Date!")}</h2>
                        <p>{t("Survey Starting On")}: <span className="text-900">{moment(survey?.startDate).format("DD-MM-YYYY")}</span> </p>
                    </div>
                ) :
                    survey?.status !== "ONGOING" ? (
                        <MainCard
                            headingCenter
                            underline
                            title={survey?.title}
                            subtitle={survey?.status === "PLANNED" ? "Survey is not yet Started" : "This Survey has been expired"}
                            extraClass="w-full lg:w-6 m-auto"
                        >
                            <p className="text-center">
                                <span className="text-900 font-bold">{survey?.status === "PLANNED" ? "Starting Date:" : ""} </span>
                                {survey?.status === "SAVED" ? formatDate(survey?.startDate) : ""}
                            </p>
                            <p className="text-center">{survey?.description}</p>
                            <div className="text-center flex justify-content-center">
                                <NoRecord />
                            </div>
                        </MainCard>
                    ) : isGuideLines ? (
                        <Guidelines setIsGuideLines={setIsGuideLines} t={t} />
                    ) : (
                        <>
                            {/* <MainCard height="auto" extraClass="pb-2" title={!isGuideLines ? "Fill the following details" : ""}>
                            <div className="flex flex-wrap justify-content-between gap-3">
                                <CustomInput label="Full Name" col={6} name="fullName" data={data} onChange={handleChange} required extraClassName="survey-input" />
                                <CustomInput label="Email Address" col={6} type="email" name="email" data={data} onChange={handleChange} required extraClassName="survey-input" />
                                <CustomDropDown
                                    label="Gender"
                                    options={genderOptions}
                                    value={{ name: data.gender }}
                                    name="gender"
                                    data={data}
                                    onChange={(e) => handleChange({ name: e.target.name, value: e.target.value.name })}
                                    required
                                    col={4}
                                    extraClassName="survey-input"
                                />
                                <CustomInputNumber label="Age" col={4} name="age" value={data.age} data={data} maxValue={70} onChange={handleChange} required extraClassName="survey-input" />
                                <CustomDropDown
                                    label="Department"
                                    value={selectedDepartment}
                                    options={departmentOptions}
                                    name="department"
                                    data={data}
                                    col={4}
                                    onChange={(e) => {
                                        handleChange({ name: e.target.name, value: e.target.value.id });
                                        setSelectedDepartment(e.target.value);
                                    }}
                                    required
                                    extraClassName="survey-input"
                                />
                            </div>
                        </MainCard> */}
                            <MainCard height="auto">
                                <div className="flex flex-wrap" style={{ minHeight: "75vh" }}>
                                    {/* <Accordion activeIndex={0} className="col-12 lg:col-3">
                                    <AccordionTab header="See All Questions">
                                        <ol className="p-2">
                                            {survey?.questions?.map((item, index) => (
                                                <li
                                                    key={index}
                                                    onClick={index <= answers.length ? () => handleQuestionChange(index) : null}
                                                    className={index === activeQuestion ? "text-900 mb-3 cursor-pointer" : "text-500 mb-3 cursor-pointer"}
                                                >
                                                    {item.question}
                                                </li>
                                            ))}
                                        </ol>
                                    </AccordionTab>
                                </Accordion> */}
                                    <div className="col-12 text-center" id="questionArea">
                                        {/* <>
                                        <h3 className="m-0">{survey?.title}</h3>
                                        <p className="text-900 m-0">Expiring on: {survey?.expiringOn?.split("T")[0]}</p>
                                        <p className="m-0 mt-2">{survey?.description}</p>
                                    </> */}
                                        <ProgressBar value={progress} displayValueTemplate={null} className="border-round-lg mt-3"></ProgressBar>
                                        <p className="text-skyblue my-7">
                                            {t("Question")} {activeQuestion + 1}/{questions.length}
                                        </p>
                                        <>

                                            <div className="font-semibold text-2xl" style={{ marginBottom: "70px" }}>
                                                {t(`questions.${questions[activeQuestion]?.question.trim()}`)}
                                            </div>

                                            <CustomRadioButtons
                                                className={"justify-content-center"}
                                                options={questions[activeQuestion]?.choices.map(choice => ({ _id: choice._id, name: t(`options.${choice.name.trim()}`), value: choice.value }))}
                                                data={{
                                                    _id: answers[activeQuestion]?.answerId,
                                                    name: answers[activeQuestion]?.answer,
                                                    value: answers[activeQuestion]?.value,
                                                }}
                                                name="choice"
                                                onChange={handleAnswer}
                                                labelPosition="top"
                                                labelbutton
                                            />
                                            <div className="flex flex-wrap justify-content-center align-items-center gap-3 lg:ml-8" style={{ marginTop: "80px" }}>
                                                {/* <ButtonPosition position="center"> */}
                                                <PrimaryButton
                                                    label={t("Previous")}
                                                    secondary
                                                    className={"px-5 lg:ml-4"}

                                                    onClick={() => {
                                                        handleQuestionChange(activeQuestion - 1);
                                                    }}
                                                    disable={activeQuestion === 0 ? true : false}
                                                />
                                                <PrimaryButton
                                                    id="nextBtn"
                                                    className={"px-6 lg:ml-4"}
                                                    label={activeQuestion + 1 === questions.length ? t("Submit") : t("Next")}
                                                    onClick={activeQuestion + 1 === questions.length ? saveSurvey : showNextQuestion}
                                                />
                                                <small className="text-gray-500 lg:ml-3">{t("press")}<span className="text-sm text-900 font-semibold"> Enter</span> <img src={EnterIcon} alt="" /></small>
                                                {/* </ButtonPosition> */}
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </MainCard>
                        </>
                    )}
            </div >
        </>
    );
}
