import React, { useEffect, useState } from "react";
import InputLayout from "./InputLayout";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import OTPInput from "otp-input-react";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { MultiSelect } from "primereact/multiselect";
import { checkPasswordRequirement, imagePath } from "../../utils/commonFunctions";
import _ from "lodash";
import { passwordRequirements } from "../../utils/constants";
import { Avatar } from "primereact/avatar";
import { Ripple } from "primereact/ripple";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { classNames } from "primereact/utils";

export function CustomForm({ children, extraclass, ...props }) {
    return (
        <div className={`grid grid-nogutter ${extraclass}`} {...props}>
            {children}
        </div>
    );
}

export const CustomInput = ({ label, name, data, value, onChange, errorMessage, extraClassName, labelClassName, required, col, inputClass, disabled = false, type = "text", ...props }) => {
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} extraClassName={extraClassName} labelClassName={labelClassName} data={data} errorMessage={errorMessage}>
            <InputText
                id={name}
                name={name}
                value={value}
                type={type}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                className={`custom-input text-sm w-full border-round-xl  bg-transparent ${inputClass ? inputClass : ""} ${errorMessage || data?.["formErrors"]?.[name] ? "p-invalid" : ""}`}
                disabled={disabled}
                {...props}
            />
        </InputLayout>
    );
};

export const CustomSearch = ({ name, data, value, onChange, iconPosition = "p-input-icon-left", col, inputClass, ...props }) => {
    return (
        <span className={`w-full bg-white ${iconPosition}`}>
            <i className="pi pi-search" />
            <InputText
                id={name}
                name={name}
                value={value}
                type="text"
                onChange={(e) => onChange && onChange(e.target.value)}
                className={`custom-input text-sm w-full border-round-xl  bg-transparent ${inputClass ? inputClass : ""}`}
                placeholder="Search"
                {...props}
            />
        </span>
    );
};

const passwordFooter = (data, name) => (
    <>
        <div className="col-12 p-0 mb-3">
            <small className="text-sm uppercase heading-color font-semibold">Password must contain:</small>
            {passwordRequirements.map((requirement, index) => (
                <React.Fragment key={index}>{checkPasswordRequirement(requirement.regex, requirement.message, data?.[name])}</React.Fragment>
            ))}
        </div>
    </>
);

export const CustomPassword = ({ label, name, labelClassName, data, value, onChange, errorMessage, hideFooter, extraClassName, required, col, inputClass, disabled = false, meter, ...props }) => {
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} labelClassName={labelClassName} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <Password
                id={name}
                name={name}
                inputClassName="w-full"
                value={value || data?.[name]}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                className={`w-full border-0 block border-round-xl bg-transparent ${inputClass ? inputClass : ""} ${errorMessage || data?.["formErrors"]?.[name] ? "p-invalid" : ""}`}
                disabled={disabled}
                {...props}
                toggleMask
                footer={!hideFooter ? () => passwordFooter(data, name) : ""}
                feedback={!hideFooter}
            />
            {/* {meter && (
                <div className="col-12 p-0 mb-3">
                    <small className="text-sm uppercase heading-color font-semibold">Password must contain:</small>
                    {passwordRequirements.map((requirement, index) => (
                        <React.Fragment key={index}>{checkPasswordRequirement(requirement.regex, requirement.message, data?.[name])}</React.Fragment>
                    ))}
                </div>
            )} */}
        </InputLayout>
    );
};

<div className="col-12 p-0 mb-3">
    <small className="text-sm uppercase heading-color font-semibold">Password must contain:</small>
</div>;

export function CustomCheckbox({ label, name, onChange, data, checked, value, disable, errorMessage, extraClassName, required, extraclass, col, inputClass, optionLabel, optionclass, ...props }) {
    return (
        <InputLayout col={col || 12} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <div className={`flex align-items-center text-md text-gray font-medium mb-3 ${extraclass}`}>
                <Checkbox
                    checked={value || data?.[name]}
                    icon="pi pi-check-square"
                    className={value ? "border-none" : ""}
                    onChange={(e) => onChange && onChange({ ...e, name, value: e.checked })}
                    id={name}
                    name={name}
                    {...props}
                    disabled={disable}
                />
                {optionLabel ? (
                    <>
                        &nbsp;&nbsp;
                        <label className={`text-white text-sm font-medium ${optionclass}`} htmlFor={name}>
                            {optionLabel}
                        </label>
                    </>
                ) : null}
            </div>
        </InputLayout>
    );
}

export const CustomOtp = ({ label, name, onChange, data, value, errorMessage, extraClassName, required, col, inputClass, disabled = false, optionLabel = "name", ...props }) => {
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <OTPInput
                value={value}
                OTPLength={6}
                renderInput={(props) => <input {...props} />}
                onChange={(e) => onChange(e)}
                otpType="number"
                disabled={false}
                className="justify-content-center"
                inputStyles={{
                    width: "34px",
                    height: "34px",
                    border: "1px solid #ECECEC",
                    borderRadius: "7px",
                }}
                renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
            />
            {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
        </InputLayout>
    );
};
export const CustomCalenderInput = ({ label, name, data, value, onChange, errorMessage, extraClassName, required, col, inputClass, ...props }) => {
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <Calendar
                id={name}
                name={name}
                value={value || data?.[name]}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.target.value })}
                className={`custom-input text-sm w-full border-round-lg  bg-transparent ${inputClass ? inputClass : "text-sm"} ${errorMessage || data?.["formErrors"]?.[name] ? "p-invalid" : ""}`}
                showIcon
                dateFormat="dd/mm/yy"
                {...props}
            />
        </InputLayout>
    );
};
export const CustomCalenderIcon = ({ label, name, data, value, onChange, errorMessage, extraClassName, filterKey, required, col, inputClass, ...props }) => {
    return (
        <div className="flex gap-2 align-items-center">
            <p className="min-w-max">{filterKey}</p>
            <label
                htmlFor="dateRange"
                className={`bg-light border block flex align-items-center justify-content-center cursor-pointer ${value ? "border-primary bg-primary" : ""}`}
                style={{ minWidth: "40px", minHeight: "40px" }}
            >
                <i className={`pi pi-calendar text-primary text-xl  ${value ? "text-white font-normal" : "font-semibold"}`} />
            </label>
            <Calendar
                inputId="dateRange"
                name="dateRange"
                value={value || data?.[name]}
                onChange={(e) => onChange && onChange(e.target.value)}
                className={` ${inputClass ? inputClass : "opacity-0"}`}
                inputClassName="p-0"
                style={{ width: 0 }}
                selectionMode="range"
                readOnlyInput
                showButtonBar
                {...props}
            />
        </div>
    );
};
export const CustomInputNumber = ({ label, name, data, value, onChange, labelClassName, errorMessage, maxValue, extraClassName, required, col, inputClass, ...props }) => {
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} labelClassName={labelClassName} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <InputNumber
                id={name}
                name={name}
                value={value || data?.[name] || null}
                onValueChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                className={`custom-input text-sm w-full border-round-lg  bg-transparent ${inputClass ? inputClass : ""} ${errorMessage || data?.["formErrors"]?.[name] ? "p-invalid" : ""}`}
                useGrouping={props.useGrouping || false}
                {...props}
                max={maxValue}
            />
            {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
        </InputLayout>
    );
};
export const CustomInputPhoneNumber = ({ label, name, data, value, onChange, labelClassName, errorMessage, maxValue, extraClassName, required, col, inputClass, ...props }) => {
    console.log("name", name);
    return (
        <InputLayout col={col || 6} label={label} name={name} required={required} labelClassName={labelClassName} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <PhoneInput
                value={data[name]}
                country="dk"
                className={`w-full number-input  ${inputClass} ${errorMessage ? "p-invalid" : null}`}
                onChange={(number, country, e, value) => onChange && onChange({ ...e, name: name, value: value })}
                {...props}
                inputClass="w-full"
            />
            {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
        </InputLayout>
    );
};
export const CustomTextArea = ({ label, name, onChange, data, value, errorMessage, extraClassName, required, col, maxLength, inputClass, disabled = false, ...props }) => {
    const [currentLength, setLength] = useState(0);
    const handleTextArea = (e) => {
        onChange && onChange({ name: e.target.name, value: e.target.value, ...e });
        setLength(e.target.value.length);
    };

    useEffect(() => {
        if (value) setLength(value.length);
        else setLength(0);
    }, [value]);
    return (
        <InputLayout
            col={col || 12}
            label={label}
            currentLength={currentLength}
            name={name}
            maxLength={maxLength}
            required={required}
            extraClassName={extraClassName}
            data={data}
            errorMessage={errorMessage}
        >
            <InputTextarea
                id={name}
                name={name}
                value={value}
                onChange={(e) => handleTextArea(e)}
                className={`w-full border-round-lg ${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                disabled={disabled}
                {...props}
                maxLength={maxLength}
            />
            {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
        </InputLayout>
    );
};
export function CustomRadioButtons({
    label,
    name,
    onChange,
    data,
    value,
    errorMessage,
    extraClassName,
    className,
    selected,
    required,
    col,
    inputClass,
    options,
    labelPosition = "",
    optionLabel,
    labelbutton,
    ...props
}) {
    return (
        <InputLayout col={col || 12} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <div className={`flex flex-wrap lg:gap-5 gap-3 ${className}`}>
                {options?.map((item, i) => (
                    <div key={i} className={`flex align-items-center gap-4  lg:mb-0 md:mb-0 mb-3 ${labelPosition === "top" ? "flex-column-reverse" : ""}`}>
                        <RadioButton
                            value={item}
                            onChange={(e) => onChange && onChange({ name, value: e.target.value, ...e })}
                            checked={_.isEqual(data || data?.[name], item)}
                            inputId={item.name}
                            name={item.name}
                            className={labelbutton ? "hidden" : ""}
                            {...props}
                        />
                        <label className={`${labelbutton ? "label-button  " : "text-gray-light "}`} htmlFor={item.name}>
                            {optionLabel ? item[optionLabel] : item?.name}
                        </label>
                    </div>
                ))}
            </div>
        </InputLayout>
    );
}

export const CustomDropDown = ({ label, name, onChange, data, value, errorMessage, extraClassName, required, col, inputClass, disabled = false, optionLabel = "name", ...props }) => {
    return (
        <InputLayout col={col || 12} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <Dropdown
                id={name}
                name={name}
                value={value || data?.[name]}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                className={`w-full border-round-lg text-sm ${inputClass ? inputClass : ""} ${errorMessage || data?.["formErrors"]?.[name] ? "p-invalid" : ""}`}
                optionLabel={optionLabel}
                placeholder={props.placeholder || `Select ${label}`}
                disabled={disabled}
                {...props}
            />
            {/* {errorMessage ? <small className="p-error">{errorMessage}</small> : null} */}
        </InputLayout>
    );
};

export const CustomMultiSelect = ({ label, name, onChange, data, value, options, errorMessage, extraClassName, required, col, inputClass, ...props }) => {
    return (
        <InputLayout col={col || 12} label={label} name={name} required={required} extraClassName={extraClassName} data={data} errorMessage={errorMessage}>
            <MultiSelect
                id={name}
                name={name}
                value={options.length ? value || data?.[name] : []}
                onChange={(e) => onChange && onChange({ ...e, name: e.target.name, value: e.value })}
                className={`w-full ${inputClass ? inputClass : ""} ${errorMessage ? "p-invalid" : ""}`}
                optionLabel="name"
                options={options}
                display="chip"
                placeholder={props.placeholder || `Select ${label}`}
                {...props}
            />
            {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
        </InputLayout>
    );
};

export const ImageUpload = ({ name = "uploadFile", onSelect, deleteFunc, accept = "image/png, image/jpg, image/jpeg", maxSize = "4096", data }) => {
    return (
        <div className="flex align-items-center mb-3 gap-3">
            <div className={`flex align-items-center gap-3 relative ${data?.image ? "w-6rem h-6rem gap-0 flex-column" : ""}`}>
                {data?.image ? (
                    <img src={imagePath(data?.image)} className="absolute w-full h-full border-circle border-1" alt="Profile " />
                ) : (
                    <Avatar icon="pi pi-user" className="mt-2" size="xlarge" shape="circle" />
                )}
                {data?.image ? (
                    <div className="flex justify-content-center align-items-center bg-white-alpha-80 w-full bottom-0 py-1 gap-2 absolute">
                        <label className="cursor-pointer">
                            <i className="pi pi-trash mr-2 text-lg" style={{ color: "red" }} onClick={deleteFunc} />
                        </label>

                        <label htmlFor={name} className="cursor-pointer">
                            <i className="pi pi-pencil text-xl" />
                            <Ripple />
                        </label>
                    </div>
                ) : (
                    <div>
                        <p className="text-xs mt-0">Max. upload size is 2MB</p>
                        <label htmlFor={name} className="cursor-pointer bg-primary relative pi-ripple border p-2">
                            <i className="pi pi-plus" /> <span>Upload</span>
                            <Ripple />
                        </label>
                    </div>
                )}
                <input type="file" filename={data?.image} className="hidden" name={name} id={name} accept={accept} onChange={onSelect} />
            </div>
        </div>
    );
};
