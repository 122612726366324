import React from 'react';
import {ReactComponent as SuccssImg} from "../../../assets/svg/success.svg";

export default function Success() {
  return (
    <div className='w-full h-screen flex align-items-center justify-content-center'>
        <div className='border p-6 bg-white shadow-1'>
            <SuccssImg/>
        </div>
    </div>
  )
}
