import React, { useEffect, useState } from "react";
import { MainCard } from "../../components/cards/card";
import { CustomDropDown, CustomForm, CustomInput, CustomInputPhoneNumber, ImageUpload } from "../../components/input/input";
import { useDispatch, useSelector } from "react-redux";
import { noOfEmployee } from "../../utils/constants";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors, uploadFile } from "../../utils/commonFunctions";
import { showToast } from "../../redux1/Slices/toastSlice";
import { updateProfileAction } from "../../redux1/Slices/loginSlice";

export default function EditProfile() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data) || JSON.parse(localStorage.getItem("User"));
    const [dialog, setDialog] = useState({
        visible: false,
        title: "",
        description: null,
        type: "",
        cancel: false,
        saveFunction: null,
    });
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        image: "",
        companyName: "",
        email: "",
        phoneNumber: "",
    });

    const handleChange = ({ name, value }) => {
        const ignoreFields = ["firstName", "lastName"];
        const formErrors = allValidations(name, value, data, ignoreFields);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const saveFunction = () => {
        let ignoreFields = ["firstName", "lastName"];
        console.log("data", data);
        if (showFormErrors(data, setData, ignoreFields)) {
            setDialog({
                visible: true,
                title: "Are you sure you want to Save ?",
                description: "Are you sure you want to save? This action cannot be undone.",
                type: "SAVE",
                cancel: true,
                saveFunction: updateProfile,
            });
        }
    };

    const setUserData = () => {
        if (user)
            setData({
                firstName: user?.firstName,
                lastName: user?.lastName,
                image: user?.image,
                companyName: user?.companyName,
                email: user?.email,
                phoneNumber: user?.phoneNumber,
            });
    };

    const updateProfile = async () => {
        let image = await uploadFile(data?.image);
        let newData = { ...data, image: image };
        setData(newData);
        dispatch(updateProfileAction({ data: newData, setDialog }));
    };

    const uploadProfileImage = async (event) => {
        let file = event?.target?.files[0];
        let fileSize = file?.size;
        if (fileSize > 2 * 1000000) {
            dispatch(showToast({ severity: "error", summary: `File size is too large`, detail: `Selected File Size: ${fileSize / 1000000}MB` }));
            return;
        } else {
            setData({ ...data, image: file, oldImage: data.image });
        }
    };

    const deleteProfileImage = () => {
        setDialog({
            visible: true,
            title: "Are you sure you want to Delete Image ?",
            type: "DELETE",
            cancel: true,
            saveFunction: () => {
                setData(!data?.oldImage ? { ...data, oldImage: data?.image, image: "" } : { ...data, image: "" });
                setDialog({ visible: false });
            },
        });
    };

    useEffect(() => {
        setUserData();
    }, [user]);

    console.log("data", data);
    return (
        <MainCard title="Edit Profile" saveFunction={saveFunction} dialog={dialog} setDialog={setDialog} cancelFuncation={setUserData}>
            <CustomForm>
                <ImageUpload onSelect={uploadProfileImage} data={data} deleteFunc={deleteProfileImage} />
                <CustomInput label="First Name" value={data.firstName} name="firstName" data={data} onChange={handleChange} placeholder="Enter" col={12} />
                <CustomInput label="Last Name" value={data.lastName} name="lastName" data={data} onChange={handleChange} placeholder="Enter" col={12} />
                <CustomInput label="Company Name" value={data.companyName} name="companyName" required data={data} onChange={handleChange} placeholder="Enter" col={12} />
                <CustomInput label="Email Address" value={data.email} name="email" disabled onChange={handleChange} placeholder="Enter" col={12} />
                <CustomInputPhoneNumber
                    label="Phone Number"
                    className="bg-white w-full border-round-lg "
                    required
                    col={12}
                    placeholder="Enter "
                    name="phoneNumber"
                    data={data}
                    onChange={handleChange}
                ></CustomInputPhoneNumber>
            </CustomForm>
        </MainCard>
    );
}
