export default class Constants {
 
    static BASE_URL = process.env.REACT_APP_BASE_URL;
    static BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    static MS_CLIENT_ID = process.env.REACT_APP_MS_CLIENT_ID;
    static MS_REDIRECT_URI = process.env.REACT_APP_MS_REDIRECT_URI;
    static GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    static END_POINT = {
        // Login - Signup

        getOtp: "user/request-otp",
        login: "user/login",
        forgetOtp: "user/forget-password",
        changePassword: "user/change-forget-password",
        checkEmail: "user/check-email",
        REQUEST_OTP: "user/request-otp",
        REGISTER: "user/signup",
        SOCIAL_LOGIN: "user/social-signin",
        LOGIN: "user/login",
        FORGEOT_PASSWORD_EMAIL: "user/forget-password",
        VERIFY_FORGOT_OTP: "user/verify-forget-otp",
        RESET_PASSWORD: "user/change-forget-password",
        UPDATE_PROFILE: "user/update",
        MY_PROFILE: "user/my-profile",
        UPLOAD_PROFILE_IMAGE: "user/upload-profile-image",
        UPDATE_PASSWORD: "user/change-password",
        DEACTIVATE_PROFILE: "user/deactivate",

        // Departments
        ADD_DEPARTMENT: "departments/create",
        GET_DEPARTMENT: "departments",
        GET_DEPARTMENT_BY_ID: "departments/",
        UPDATE_DEPARTMENT: "departments/update/",
        DELETE_DEPARTMENT: "departments/delete/",

        // Categories and Questions
        GET_CATEGORIES: "categories",
        GET_QUESTIONS: "questions",

        // Dashboard
        ANALYTICS_OVERVIEW: "survey-analytics",
        CATEGORY_SCORES: "survey-analytics/category-scores",
        DEPARTMENT_SCORES: "survey-analytics/department-scores",
        DEPARTMENT_SCORES_BY_CATEGORY: "survey-analytics/department-scores-by-category/",
        FOCUS_AREA: "survey-analytics/focus-area",
        FOCUS_AREA_BY_DEPARTMENT: "survey-analytics/focus-area-by-department/",
        ANALYTICS_PROGRESS: "survey-analytics/progress-by-categoriesv2",
        CATEGORY_SCORE_BY_ID: "survey-analytics/yearly-department-scores/",
        DEPARTMENT_SCORE_BY_ID: "survey-analytics/yearly-category-scores/",

        // Survey
        ADD_SURVEY: "surveys/create",
        GET_SURVEY: "surveys",
        GET_PUBLIC_SURVEY: "surveys/public",
        SEND_SURVEY: "surveys/send-survey-link/",
        UPDATE_SURVEY: "surveys/update/",
        SUBMIT_SURVEY: "user-surveys/create",

        // Services
        GET_SERVICES: "services",
        REQUEST_SERVICE: "request-services",
        REQUESTED_SERVICES: "request-services",

        // Employees
        EMPLOYEES: "employees",
        EMPLOYEES_BY_DEPT: "employees/by-dept-ids",
        EMPLOYEES_UPLOAD: "employees/upload",
        EMPLOYEES_VALIDATE_CSV: "employees/validate-csv",
        EMPLOYEES_BULK_UPDATE_DEPT: "employees/update-departments",

        // Help
        HELP: "help",

        // Notifications
        NOTIFICATIONS: "notifications",
    };
}
