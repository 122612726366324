import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";
import { formatDate } from "../../utils/commonFunctions";

const initialState = {
    data: {},
    surveyDetail: {},
    surveyList: [],
};

// Create Survey
export const addSurveyAction = createAsyncThunk("survey/add_survey", async ({ data, next, setDialog }, { dispatch }) => {
    try {
        dispatch(showLoader());
        setDialog({ visible: false });
        const payload = {
            ...data,
            startDate: await formatDate(data?.startDate, "ISO"),
            expiringOn: await formatDate(data?.expiringOn, "ISO"),
        };
        const res = await api("post", Constants.END_POINT.ADD_SURVEY, payload);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            next(res.data._id);

            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

// Update Survey

export const upadteSurveyAction = createAsyncThunk("survey/update_survey", async ({ data, surveyId, next }, { dispatch }) => {
    try {
        dispatch(showLoader());

        const payload = {
            ...data,
            startDate: await formatDate(data?.startDate, "ISO"),
            expiringOn: await formatDate(data?.expiringOn, "ISO"),
        };
        const res = await api("put", Constants.END_POINT.UPDATE_SURVEY + surveyId, data.isDeleted ? { isDeleted: true } : data.status === "INACTIVE" ? data : payload);

        if (res.success) {
            // dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            next();

            return res.data;
        } else {
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

// Submit - Fill User Survey

export const submitSurveyAction = createAsyncThunk("survey/submit_survey", async ({ surveyData, navigate }, { dispatch }) => {
    try {
        dispatch(showLoader());

        const { survey: surveyId, ...dataWithoutSurvey } = surveyData;

        const payload = {
            surveyInstanceId: surveyId,
            ...dataWithoutSurvey,
        };
        const res = await api("post", Constants.END_POINT.SUBMIT_SURVEY, payload);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            navigate("/success");

            return res.data;
        } else {
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

// Get Survey By Id
export const surveyByIdAction = createAsyncThunk("survey/get_surveyById", async ({ id, empId }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        const res = await api("get", empId ? Constants.END_POINT.GET_PUBLIC_SURVEY + "/" + id + "?empId=" + empId : Constants.END_POINT.GET_SURVEY + "/" + id);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(surveySlice.actions.setSurveyDetails(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// Send Survey to employees
export const activateSurveyAction = createAsyncThunk("survey/update_survey", async ({ setDialog, surveyId, navigate }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());

        const payload = {
            status: 'ACTIVE',
        };
        const res = await api("put", Constants.END_POINT.UPDATE_SURVEY + surveyId, payload);
        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            setDialog({ visible: false });
            navigate("/surveys/active");                    
        } else {
            setDialog({ visible: false });
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

// Get Surveys with Filter
export const getSurveysAction = createAsyncThunk("survey/get_surveys", async ({ query }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        const res = await api("get", Constants.END_POINT.GET_SURVEY + "?" + query);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(surveySlice.actions.setSurveyList(res.data));
            return res.data;
        } else {
            // dispatch(showToast({ severity: "error", summary: res.message }));
            dispatch(surveySlice.actions.setSurveyList(res.data));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

const surveySlice = createSlice({
    name: "survey",
    initialState,
    reducers: {
        setSurveyDetails: (state, action) => {
            state.surveyDetail = action.payload;
        },
        setSurveyList: (state, action) => {
            state.surveyList = action.payload;
        },
    },
});

export const { setSurveyDetails } = surveySlice.actions;

export default surveySlice.reducer;
